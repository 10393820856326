import './src/styles/index.scss';
import {UserContextProvider} from '@parallelpublicworks/entitysync';
import Default from "./src/gatsby-theme-carbon/templates/Default";
import LoginWrap from "./src/components/LoginWrap";
import { QueryParamProvider } from 'use-query-params';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import unprotectedPaths from './src/data/unprotectedPaths.json'

export const wrapRootElement = ({ element }) => {
	return (
    <QueryParamProvider>
      <UserContextProvider>
        {element}
      </UserContextProvider>
    </QueryParamProvider>
	);
}

export const wrapPageElement = ({ element, props }) => {
  let pageContext = { ...props.pageContext };
  const is_unprotected = unprotectedPaths.find((p) => props.location.pathname.includes(p))
  if (is_unprotected) {
    return (
//      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
        <Default location={props.location} pageContext={pageContext}>
          {element}
        </Default>
//      </GoogleReCaptchaProvider>
    );
  } else {
    return (
//      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
        <LoginWrap location={props.location}>
          <Default location={props.location} pageContext={pageContext}>
            {element}
          </Default>
        </LoginWrap>
//      </GoogleReCaptchaProvider>
    );
  }
}
